import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Message, Table, TableColumn, Dialog, Input, Button, InputNumber, MessageBox } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/common.less'

import VueClipBoard from 'vue-clipboard2'

Vue.use(VueClipBoard)

Vue.config.productionTip = false
Vue.prototype.$message = Message;
Vue.use(Table);
Vue.use(Dialog);
Vue.use(TableColumn);
Vue.use(Button);
Vue.use(Input);
Vue.use(InputNumber);
Vue.prototype.$confirm = MessageBox.confirm;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
