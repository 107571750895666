import axios from 'axios';
// import { getToken, setToken, removeToken } from '@/utils/util';
const service = axios.create({
  timeout: 5000
});

service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    // config.headers.token = getToken() || "";
    if (config.url.includes('?')) {
      config.url = config.url + '&date=' + ((new Date()).getTime()) // 兼容IE11兼容模式请求相同的 URL ，浏览器默认会使用之前的缓存数据，而不是重新请求接口
    } else {
      config.url = config.url + '?date=' + ((new Date()).getTime()) // 兼容IE11兼容模式请求相同的 URL ，浏览器默认会使用之前的缓存数据，而不是重新请求接口
    }
    return config;
  },
  (error) => {
    return Promise.reject({
        msg: '网络错误',
        code: error.response.status
    });
  }
);


service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code === 0) {
      return res.data;
    } else {
      return Promise.reject({
        msg: res.msg || res.message,
        code: res.code
      });
    }
  },
  (error) => {
    if (error && error.response && error.response.status) {
        return Promise.reject({
            msg: '系统错误',
            code: error.response.status
        });
    }
  }

);

export default service;
