import request from "./request";
// 查询会议是否需要密码访问
export function getMeetingPasswordNeed(params) {
  return request({
    url: "/api/meeting/query-password-switch",
    method: "get",
    params,
  });
}
// 获取会议详细信息
export function getMeetingDetail(params) {
  return request({
    url: "/api/meeting/detail",
    method: "get",
    params,
  });
}
//
export function deleteSeat(params) {
  return request({
    url: "/api/meeting/seat",
    method: "delete",
    params,
  });
}

// 座位信息刷盘/追加(批量操作)
export function seatBrush(data) {
  return request({
    url: "/api/meeting/seat/brush",
    method: "post",
    data,
  });
}
// 添加座位信息
export function addSeat(data) {
  return request({
    url: "/api/meeting/seat",
    method: "post",
    data,
  });
}
// 清空信息(含人物)
export function seatClean(data) {
  return request({
    url: "/api/meeting/seat/clear",
    method: "post",
    data,
  });
}
// 重置座位信息
export function seatReset(data) {
  return request({
    url: "/api/meeting/seat/flush",
    method: "post",
    data,
  });
}
// 文件导入人员座位信息
export function uploadExcel(data) {
  return request({
    url: "/api/meeting/seat/brush-file",
    method: "post",
    async : false,
    cache : false,
    contentType : false,
    processData : false,
    'Content-Type': '',
    data,
  });
}
// 座位信息刷盘/追加(批量操作)
export function seatModify(data) {
  return request({
    url: "/api/meeting/seat",
    method: "put",
    data,
  });
}

export function autoArrange(data) {
  return request({
    url: "/api/meeting/seat/auto-arrange",
    method: "post",
    data,
  });
}
