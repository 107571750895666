<template>
    <div class="home meeting-detail">
        <div class="show-guide" v-if="showGuide">
            <div class="guide-mask" @click="closeGuide"></div>
            <img class="guide-img" src="../assets/images/meeting-detail/1.gif" alt="">
        </div>
        <input type="file" id="file" style="display:none;"
               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>

        <div class="container-box">
            <div class="seats-main">
                <div class="seat-alias" :style="{width: seatWidth + 'px'}">{{ alias }}</div>
                <div class="seat-box" :style="{width: seatWidth + 'px'}">
                    <div v-show="hiddenTitle" class="hidden-title">{{ alias }}</div>
                    <div id="seat-container"></div>
                </div>
            </div>
            <div v-if="showBtn" class="my-btns">
                <el-button @click="exportIn" icon="el-icon-my-export-in">导入座位表</el-button>
                <el-button @click="exportOut" icon="el-icon-my-export-out">导出座位图</el-button>
                <el-button @click="shareFunc" icon="el-icon-my-share">共享编辑</el-button>
                <el-button @click="downloadFunc" icon="el-icon-my-download">模板下载</el-button>
                <el-button @click="addPersonFunc" icon="el-icon-my-add">新增人员</el-button>
                <el-button @click="autoSeats" icon="el-icon-my-auto">自动排位</el-button>
                <el-button @click="seatsReset" style="display: none;" icon="el-icon-my-reset">全部重置</el-button>
                <el-button @click="clearInfo" style="display: none;" icon="el-icon-my-clear">全部清空</el-button>
            </div>
            <div class="user-list" v-show="showBtn">
                <div class="user-name">待排位专家</div>
                <div class="user-box" id="user-box">
                    <div v-for="peo in notInSeats" class="person" @dragstart="dragStart" :data-id="peo.id" :person-name="peo.person_alias"
                         :membership="peo.membership" :role="peo.role" :seat-id="0" :draggable="true">
                        <div class="person-name">{{ peo.person_alias }} <span class="peo-role">{{ peo.role }}</span>
                        </div>
                        <div class="person-info">
                            <div>{{ peo.membership }}</div>

                        </div>
                    </div>
                </div>

            </div>
        </div>


        <el-dialog width="700px" title="密码" :visible.sync="showPassWord" :show-close="false">
            <div>
                <el-input v-model="password" placeholder="请输入密码" @keyup.enter.native="surePassword"></el-input>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelPassword">取 消</el-button>
                <el-button type="primary" @click="surePassword">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog width="700px" :title="currentPerson.person_alias ? '座位替换' : '座位选择'" :visible.sync="showSeat" :show-close="false">
            <div class="seat-dialog-box">
                <div>当前占座人员：<span class="colorRed">{{ currentPerson.person_alias }}</span></div>
                <div class="colorRed"> {{ currentPerson.role }} </div>
                <div class="colorRed"> {{ currentPerson.membership }} </div>
            </div>
            <div>
                <div class="dialog-box">
                    <el-table
                            @row-dblclick="changeSeat"
                            :data="notInSeats"
                            style="width: 100%">
                        <el-table-column
                                prop="person_alias"
                                label="姓名"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="role"
                                label="身份角色"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="membership"
                                label="单位"
                                width="180">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="deleteSeatFunc">删除当前人员</el-button>
                <el-button @click="modifySeatFunc">移出座位</el-button>
                <el-button @click="showSeat = false">取 消</el-button>
                <el-button type="primary" @click="showSeat = false">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog width="700px" title="上传结果" :visible.sync="uploadResult" :show-close="false">
            <div>
                <div class="dialog-box">
                    <el-table
                            :data="uploadList"
                            style="width: 100%">
                        <el-table-column
                                prop="id"
                                label="id"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="person_alias"
                                label="姓名"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="role"
                                label="身份角色"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="membership"
                                label="单位"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="membership"
                                label="原因"
                                width="120">
                            <template slot-scope="scope">
                                <div>{{ getReason[scope.row.verify_status] }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </el-dialog>

        <el-dialog width="700px" title="新增人员" :visible.sync="addPersonVisiable" :show-close="false">
            <div class="add-person">
                <div class="add-person-item">
                    <div class="person-label require-label">姓名 <span>*</span></div>
                    <div class="person-value">
                        <el-input v-model="addPerson.person_alias" placeholder="请输入姓名"></el-input>
                    </div>
                </div>
                <div class="add-person-item">
                    <div class="person-label">身份角色</div>
                    <div class="person-value">
                        <el-input v-model="addPerson.role" placeholder="请输入身份角色"></el-input>
                    </div>
                </div>
                <div class="add-person-item">
                    <div class="person-label">列</div>
                    <div class="person-value">
                        <el-input-number :min="0" v-model="addPerson.len_index"
                                         placeholder="请输入列"></el-input-number>
                    </div>
                </div>
                <div class="add-person-item">
                    <div class="person-label">行</div>
                    <div class="person-value">
                        <el-input-number :min="0" v-model="addPerson.wide_index"
                                         placeholder="请输入行"></el-input-number>
                    </div>
                </div>

                <div class="add-person-item">
                    <div class="person-label">单位</div>
                    <div class="person-value">
                        <el-input v-model="addPerson.membership" placeholder="请输入单位"></el-input>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelAdd">取 消</el-button>
                <el-button type="primary" @click="sureAdd">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
import {
    deleteSeat,
    addSeat,
    getMeetingDetail,
    getMeetingPasswordNeed, seatBrush, seatModify, seatReset, seatClean, uploadExcel, autoArrange,
} from '@/api/apis';
import html2canvas from "html2canvas";

var _this = null;
var seatContainer = null;
var userBox = null;

export default {
    name: 'HomeView',
    components: {},
    data() {
        return {
            seatSpecs: 63, // 座位规格
            getReason: {
                100: '座位号冲突',
                200: '人员已存在',
                400: '座位号超出边界',
                500: '姓名不能为空',
            },
            showGuide: false, // 是否展示导航
            uploadResult: false,
            showBtn: false,
            uploadList: [],
            addPersonVisiable: false,
            addPerson: {},
            currentPerson: {},
            seat_len: 0,  // x轴
            seat_wide: 0,  // y轴
            seatWidth: 0,
            allPerson: [], // 该会议的所有参与人员
            notInSeats: [], // 不在座位的人员列表
            seatPerson: [], // 在座位的人员列表
            meeting_id: '',
            alias: '',
            password_switch: '', // 是否需要密码
            showPassWord: false,
            hiddenTitle: false,
            showSeat: false,
            password: '',
        }
    },
    mounted() {
        _this = this;
        this.meeting_id = this.$route.query.meeting_id;

        document.getElementById('file').addEventListener('change', function (e) {
            var files = e.target.files;
            if (files.length == 0) return;
            var f = files[0];
            console.log(f);
            if (!/\.xlsx$/g.test(f.name)) {
                alert('仅支持读取xlsx格式！');
                return;
            }
            let formData = new FormData();
            formData.append('name', f.name)
            formData.append('files', f)
            formData.append('meeting_id', _this.meeting_id)
            formData.append('password', _this.password)
            uploadExcel(formData).then((res) => {
                let arr = []
                res.brush_data.forEach(item => {
                    if (item.verify_status > 0) {
                        arr.push(item)
                    }
                })
                console.log(arr, '---arr---');
                if (arr.length) {
                    _this.uploadList = [...arr];
                    _this.uploadResult = true;
                }
                e.target.value = ''
                _this.getMeetingDetailFunc(true);
            }).catch((err) => {
                console.error(err);
                e.target.value = ''
                _this.$message.error(err.msg);
            })
            // _this.readWorkbookFromLocalFile(f, function(workbook) {
            //     e.target.value='';
            //     _this.readWorkbook(workbook);
            // });
        });

        if (this.meeting_id) {
            let pas = localStorage.getItem(("meetingPassword" + this.meeting_id));
            let result = []
            if (pas) {
                result = pas.split('_')
                let nowTime = new Date().getTime() - result[1];
                if (nowTime > 0 && nowTime < 60 * 60 * 1000) {
                    this.password = result[0];
                } else {
                    localStorage.setItem(("meetingPassword" + this.meeting_id), '');
                }
            }
            console.log(result, '--result---');
            getMeetingPasswordNeed({
                meeting_id: this.meeting_id,
            }).then((res) => {
                this.password_switch = res.password_switch;
                console.log(res);
                if (res.password_switch == 1 && !this.password) {
                    this.showPassWord = true;
                } else {
                    this.getMeetingDetailFunc();
                }
            }).catch((e) => {
                console.error(e);
                _this.$message.error(e.msg);
            })
        }

        seatContainer = document.getElementById("seat-container");
        userBox = document.getElementById("user-box");
        // seat-person 座位上的人绑定点击事件
        seatContainer.addEventListener("click", function (event) {
            //执行点击事件触发的操作
            if (event.target.classList.contains("seat-person")) {
                let id = event.target.getAttribute('data-id');
                let seatId = event.target.getAttribute('seat-id');
                let membership = event.target.getAttribute('membership');
                let role = event.target.getAttribute('role');
                let person_alias = event.target.getAttribute('person-name');
                _this.currentPerson = {
                    id,
                    membership,
                    role,
                    seatId,
                    person_alias: person_alias,
                }
                console.log(_this.currentPerson);
                _this.showSeat = true;
            } else if (event.target.classList.contains("seat")) {
                console.log(event.target.id);
                _this.currentPerson = {
                    seatId: event.target.id
                };
                _this.showSeat = true;
            }
        });


        // 鼠标进入座位表时，添加hover态样式
        seatContainer.addEventListener("dragenter", function (event) {
            event.preventDefault();
            if (event.target.classList.contains("seat")) {
                event.target.classList.add("hover");
            }
        });

        // 鼠标离开座位表时，移除hover态样式
        seatContainer.addEventListener("dragleave", function (event) {
            event.preventDefault();
            if (event.target.classList.contains("seat")) {
                event.target.classList.remove("hover");
            }
        });

        // 允许drop事件发生
        seatContainer.addEventListener("dragover", function (event) {
            event.preventDefault();
        });

        // 允许drop事件发生
        userBox.addEventListener("dragover", function (event) {
            event.preventDefault();
        });


    },
    methods: {
        cancelAdd() {
            _this.addPersonVisiable = false;
            _this.addPerson = {};
        },
        closeGuide() {
            _this.showGuide = false;
        },
        sureAdd() {
            console.log(this.addPerson);
            if (!this.addPerson.person_alias) {
                _this.$message('请输入专家姓名');
                return;
            }
            addSeat({
                person_alias: this.addPerson.person_alias,
                role: this.addPerson.role,
                membership: this.addPerson.membership,
                len_index: this.addPerson.len_index ? this.addPerson.len_index : 0,
                wide_index: this.addPerson.wide_index ? this.addPerson.wide_index : 0,
                password: this.password,
                meeting_id: this.meeting_id
            }).then((res) => {
                console.log(res);
                _this.getMeetingDetailFunc(true);
                _this.addPersonVisiable = false;
                _this.addPerson = {};
            }).catch((e) => {
                console.error(e);
                _this.$message.error(e.msg);
            })
        },
        async changeSeat(row, column, event) {

            if (this.currentPerson.id) {
                await this.modifySeatFuncAsync(this.currentPerson, 0, 0)
            }
            let site = this.currentPerson.seatId.match(/\d+/g)
            await this.modifySeatFuncAsync(row, site[0], site[1])
            _this.getMeetingDetailFunc(true);
            _this.showSeat = false;
            console.log(row);
            console.log(this.currentPerson);
        },
        readWorkbookFromLocalFile(file, callback) {
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                var workbook = XLSX.read(data, {type: 'binary'});
                if (callback) callback(workbook);
            };
            reader.readAsBinaryString(file);
        },
        readWorkbook(workbook) {
            const _this = this;
            var sheetNames = workbook.SheetNames; // 工作表名称集合
            var worksheet = workbook.Sheets[sheetNames[0]]; // 这里我们只读取第一张sheet
            var json = XLSX.utils.sheet_to_json(worksheet);
            var json1 = XLSX.utils.sheet_to_json(worksheet, {header: 1, defVal: '', range: 1});
            console.log(json, '---json');
            console.log(json1, '---json1');
            let arr = []
            let result = true;
            json1.forEach((item) => {
                console.log(item, '-----item');
                item[0] = item[0] ? item[0] : 0;
                item[1] = item[1] ? item[1] : 0;
                if (item[2] == undefined || (isNaN(item[0])) || isNaN(item[1])) {
                    result = false;
                } else {
                    arr.push({
                        role: item['4'],
                        membership: item['3'],
                        wide_index: item['1'],
                        len_index: item['0'],
                        person_alias: item['2'],
                    })
                }
            })
            console.log(arr);
            if (!result) {
                _this.$message({
                    type: 'error',
                    message: '导入有误，请检查后导入'
                });
                return;
            }
            let nowSeat = document.getElementsByClassName('seat');
            for (let nowSeatElement of nowSeat) {
                let s = nowSeatElement.querySelectorAll('.seat-person') ? nowSeatElement.querySelectorAll('.seat-person')[0] : '';
                if (s) {
                    let site = s.getAttribute('seat-id').match(/\d+/g)

                    let a = arr.find((excelRow) => {
                        return (excelRow.wide_index == site[1] && excelRow.len_index == site[0])
                    })
                    console.log(a, '----a---');

                    // arr: excel数据   a: excel和当前座位冲突的座位
                    if (!a) {
                        // 如果a不存在，则加入到excel数据中进行推送
                        console.log(s, '----s----');
                        arr.push({
                            role: s.getAttribute('role'),
                            membership: s.getAttribute('membership'),
                            wide_index: site[1],
                            len_index: site[0],
                            person_alias: s.textContent,
                        })
                    } else {
                        // 如果a存在。则将其移入等待排队
                        arr.push({
                            role: s.getAttribute('role'),
                            membership: s.getAttribute('membership'),
                            wide_index: 0,
                            len_index: 0,
                            person_alias: s.textContent,
                        })
                    }
                }

            }
            _this.notInSeats.forEach(ss => {
                arr.push({
                    role: ss.role,
                    membership: ss.membership,
                    wide_index: 0,
                    len_index: 0,
                    person_alias: ss.person_alias,
                })
            })

            seatBrush({
                meeting_id: this.meeting_id,
                password: this.password,
                append_switch: 0,
                brush_data: arr
            }).then((r) => {
                _this.getMeetingDetailFunc(true);
            }).catch((e) => {
                console.error(e);
                _this.$message.error(e.msg);
            })
        },
        seatsReset() {
            this.$confirm('此操作将重置座位信息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                seatReset({
                    meeting_id: _this.meeting_id,
                    password: _this.password,
                }).then((res) => {
                    _this.getMeetingDetailFunc(true);
                    _this.getMeetingDetailFunc(true);
                }).catch((e) => {
                    console.error(e);
                    _this.$message.error(e.msg);
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        clearInfo() {
            this.$confirm('当前操作会清空所有专家，请谨慎操作！', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                seatClean({
                    meeting_id: _this.meeting_id,
                    password: _this.password,
                }).then((res) => {
                    _this.getMeetingDetailFunc(true);
                }).catch((e) => {
                    console.error(e);
                    _this.$message.error(e.msg);
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        autoSeats() {
            this.$confirm('当前操作会将所有未排位专家按照从“中间向两边，从前到后”进行排序，是否执行操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                autoArrange({
                    meeting_id: _this.meeting_id,
                    password: _this.password,
                }).then((res) => {
                    _this.getMeetingDetailFunc(true);
                }).catch((e) => {
                    console.error(e);
                    _this.$message.error(e.msg);
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });

        },
        addPersonFunc() {
            this.addPersonVisiable = true;
        },
        downloadFunc() {
            window.open(window.location.origin + '/品致排位系统模板.xlsx', "_blank")
        },
        shareFunc() {
            let str = '会议地址：http://seat.pinzhi2019.com/?meeting_id=' + this.meeting_id
            if (this.password_switch == 1) {
                str += "    密码：" + this.password
            }
            this.$copyText(str).then(
                function (e) {
                    console.log("copy arguments e:", e);
                    _this.$message.success('复制成功');
                },
                function (e) {
                    console.log("copy arguments e:", e);
                    _this.$message.error('复制失败');
                }
            );
        },
        exportIn() {
            document.getElementById('file').click();
        },
        exportOut() {
           // todo
            setTimeout(() => {
                // 防止截图不全
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                _this.hiddenTitle = true;
                _this.$nextTick(() => {
                    let ele = document.querySelector('.seat-box');
                    let eleMain = document.querySelector('.seats-main');
                    console.log(eleMain, '===ele.clientWidth==');
                    console.log(ele.clientHeight, '===ele.clientHeight==');
                    html2canvas(ele, {
                        useCORS: true, // 【重要】开启跨域配置
                        scrollY: 0,
                        width: ele.clientWidth, //dom 原始宽度
                        height: ele.clientHeight,
                        scrollX: 0,
                        scale: window.devicePixelRatio < 3 ? window.devicePixelRatio : 2,
                        allowTaint: true, // 允许跨域图片
                    }).then(canvas => {
                        const imgData = canvas.toDataURL('image/jpeg', 1.0);
                        console.log(imgData);
                        _this.hiddenTitle = false;
                        // const imgUrl = canvas.toDataURL('image/jpeg')
                        // const image = document.createElement('img')
                        // image.src = imgUrl
                        // // 将生成的图片放到 类名为 content 的元素中
                        // document.querySelector('.content').appendChild(image)
                        const a = document.createElement('a')
                        a.href = imgData
                        // a.download 后面的内容为自定义图片的名称
                        a.download = _this.alias + '.jpg';
                        a.click()
                    })
                }, 300)
                })
        },
        async modifySeatFuncAsync(data, x, y) {
            await seatModify({
                meeting_id: _this.meeting_id,
                password: _this.password,
                id: data.id,
                person_alias: data.person_alias,
                role: data.role,
                membership: data.membership,
                len_index: x,
                wide_index: y,
            }).then((res) => {
            }).catch((e) => {
                console.error(e);
                _this.$message.error(e.msg);
            })
        },
        modifySeatFunc(isRefresh = false) {
            seatModify({
                meeting_id: _this.meeting_id,
                password: _this.password,
                id: _this.currentPerson.id,
                person_alias: _this.currentPerson.person_alias,
                role: _this.currentPerson.role,
                membership: _this.currentPerson.membership,
                len_index: 0,
                wide_index: 0,
            }).then((res) => {
                console.log(res);
                _this.showSeat = false;
                _this.getMeetingDetailFunc(true);
            }).catch((e) => {
                _this.showSeat = false;
                console.error(e);
                _this.$message.error(e.msg);
            })
        },
        deleteSeatFunc() {
            console.log(this.currentPerson);
            deleteSeat({
                id: _this.currentPerson.id,
                meeting_id: _this.meeting_id,
                password: _this.password,
            }).then((res) => {
                console.log(res);
                _this.showSeat = false;
                _this.getMeetingDetailFunc(true);
            }).catch((e) => {
                _this.showSeat = false;
                console.error(e);
                _this.$message.error(e.msg);
            })
        },
        getMeetingDetailFunc(isAdd = false) {
            getMeetingDetail({
                meeting_id: this.meeting_id,
                password: this.password,
            }).then((res) => {
                console.log(res);
                _this.alias = res.alias
                document.title = res.alias;
                localStorage.setItem(("meetingPassword" + this.meeting_id), (this.password + "_" + new Date().getTime()))
                // "op_status": 0,//操作状态:0=可查看(查看座位图),1=可移动（可以拖拽专家座位图）,2=已关闭（提示关闭排座，用户无法访问）
                if (res.op_status == 0) {
                    this.showBtn = true;
                    this.renderSeats(res);
                } else if (res.op_status == 1) {
                    this.showBtn = true;
                    if (!isAdd) {
                        if (!localStorage.getItem('guide')) {
                            localStorage.setItem('guide', 1);
                            _this.showGuide = true;
                        }
                        // 只有op_status 为 1 的时候，才可以移动      放置人物到座位表
                        seatContainer.addEventListener("drop", function (event) {
                            console.log(event.target);
                            event.preventDefault();
                            console.log(event.target.classList.contains("seat") && event.target.querySelectorAll('.seat-person').length > 0, '---已经有元素啦~~~');
                            // 如果当前位置已经有人占据了，则提示
                            if (!event.target.classList.contains("seat")) {
                                _this.$message.error('请放置在正确的座位');
                                return;
                            }
                            // 如果当前位置已经有人占据了，则提示
                            if (event.target.querySelectorAll('.seat-person').length > 0) {
                                _this.$message.error('当前座位已被占用，请先移除');
                                return;
                            }
                            // event.target指的是当前落地座位
                            if (event.target.classList.contains("seat")) {
                                event.target.classList.remove("hover");
                                let arr = event.dataTransfer.getData("text/plain").split('_1_');
                                var personName = arr[0];
                                var personId = arr[1];
                                var membership = arr[2];
                                var role = arr[3];
                                var originSeatId = arr[4];  // 放置元素的原始座位id，没有则代表从人物列表来
                                var seatId = event.target.id;
                                var seat = document.getElementById(seatId);
                                if (!seat.classList.contains("occupied")) {
                                    var person = _this.allPerson.find(function (person) {
                                        return person.id == personId;
                                    });
                                    console.log(seat, '---seat---');
                                    if (originSeatId !== '0') {
                                        let originSeat = document.getElementById(originSeatId);
                                        originSeat.removeChild(originSeat.firstChild)
                                    }

                                    var a = document.createElement("div");
                                    a.className = "seat-person " + seatId;
                                    a.setAttribute('data-id', person.id);
                                    a.setAttribute('seat-id', seatId);
                                    a.setAttribute('add-id', seat.id);
                                    a.setAttribute('membership', person.membership);
                                    a.setAttribute('role', person.role);
                                    a.setAttribute('person-name', person.person_alias);
                                    a.textContent = person.person_alias;
                                    a.draggable = true;
                                    a.addEventListener("dragstart", _this.dragStart);
                                    seat.appendChild(a);

                                    let site = seatId.match(/\d+/g)
                                    console.log(site, '--site--');
                                    // 拖拽成功后提交座位变更接口，并重新请求会议详情
                                    seatModify({
                                        meeting_id: _this.meeting_id,
                                        id: person.id,
                                        person_alias: person.person_alias,
                                        password: _this.password,
                                        role: person.role,
                                        membership: person.membership,
                                        len_index: site[0],
                                        wide_index: site[1],
                                    }).then(mod => {
                                        _this.getMeetingDetailFunc(true);
                                    }).catch((e) => {
                                        console.error(e);
                                        _this.$message.error(e.msg);
                                    })

                                    // // 如果是从人物列表拖拽来的
                                    // let fromList = event.target.classList.contains("person")
                                    // if (fromList) {
                                    //     // 从人物列表移动到座位
                                    //     var index = _this.notInSeats.findIndex(function (person) {
                                    //         return person.id == personId;
                                    //     });
                                    //     if (index !== -1) {
                                    //         // 删除数组中的对象
                                    //         _this.notInSeats.splice(index, 1);
                                    //     }
                                    // } else {
                                    //     // 从座位移动到另外一个座位
                                    // }
                                }
                            }
                        });
                        userBox.addEventListener("drop", async (event) => {
                            console.log(event.target, '========');
                            event.preventDefault();
                            let arr = event.dataTransfer.getData("text/plain").split('_1_');
                            console.log(arr, '---arr---');
                            var personName = arr[0];
                            var personId = arr[1];
                            var membership = arr[2];
                            var role = arr[3];
                            await _this.modifySeatFuncAsync({
                                id: personId,
                                person_alias: personName,
                                role: role,
                                membership: membership,
                            }, 0, 0)
                            _this.getMeetingDetailFunc(true);
                            console.log(arr);
                        });
                    }
                    this.renderSeats(res);
                } else {
                    this.$message.error('当前排座已关闭，无法访问')
                }
            }).catch((e) => {
                console.error(e);
                if (e.code === 400422) {
                    localStorage.setItem(("meetingPassword" + this.meeting_id), '')
                    _this.$message.error('密码错误，请刷新页面重试');
                } else {
                    _this.$message.error(e.msg);
                }

            })
        },
        renderSeats(data) {
            console.log(data, '---s--s-');
            if (window.screen.width < 750) {
                this.seatWidth = data.seat_len * this.seatSpecs + 100;
            } else {
                this.seatWidth = data.seat_len * this.seatSpecs + 100;
            }

            let notInSeats = [];
            let seatPerson = [];
            this.allPerson = [...data.seat_join];
            data.seat_join.forEach((person) => {
                if (person.len_index == 0 && person.wide_index == 0) {
                    notInSeats.push(person)
                } else {
                    seatPerson.push(person)
                }
            })
            this.notInSeats = [...notInSeats];
            this.seatPerson = [...seatPerson];
            this.createSeatTable(data.seat_len, data.seat_wide, notInSeats, seatPerson); // 设置座位表大小
        },
        cancelPassword() {
            console.log(1);
            this.password = '';
            this.showPassWord = false;
        },
        surePassword() {
            this.showPassWord = false;
            this.getMeetingDetailFunc();
        },
        // 创建座位表
        createSeatTable(seat_len, seat_wide, notInSeats, seatPerson) {
            seatContainer.innerHTML = "";
            let line = Math.ceil(seat_len / 2);
            for (var row = 1; row <= seat_wide; row++) {  // y轴
                for (var col = 1; col <= seat_len; col++) {  // x轴
                    var seatId = "x" + col + "y" + row;
                    var seat = document.createElement("div");
                    seat.style.width = _this.seatSpecs - 10 + 'px';
                    seat.style.height = _this.seatSpecs - 10 + 'px';
                    seat.id = seatId;
                    if (line === col) {
                        seat.className = "seat seat-line";
                    } else {
                        seat.className = "seat";
                    }
                    // seat.textContent = seatId;
                    if (col === 1) {
                        var linex = document.createElement("div");
                        linex.className = "linex linex" + 1;
                        linex.textContent = row + '排';
                        seat.appendChild(linex)
                    }
                    if (row === 1) {
                        var liney = document.createElement("div");
                        liney.className = "liney liney" + 1;
                        liney.textContent = col + '号';
                        seat.appendChild(liney)
                    }

                    let person = seatPerson.find(function (person) {
                        return person.len_index == col && person.wide_index == row;
                    });

                    if (person && person.id) {
                        var a = document.createElement("div");
                        a.className = "seat-person " + seatId;
                        a.setAttribute('data-id', person.id);
                        a.setAttribute('seat-id', seatId);
                        a.setAttribute('add-id', seat.id);
                        a.setAttribute('membership', person.membership);
                        a.setAttribute('role', person.role);
                        a.setAttribute('person-name', person.person_alias);
                        a.textContent = person.person_alias;
                        a.draggable = true;
                        a.addEventListener("dragstart", _this.dragStart);
                        seat.appendChild(a);
                    }

                    seatContainer.appendChild(seat);
                }
            }
        },

        // 拖拽开始
        dragStart(event) {
            // console.log(event.target.getAttribute('data-id'), '--拖拽开始--');
            console.log(event.target, '--拖拽开始--');
            event.dataTransfer.setData("text/plain", event.target.getAttribute('person-name') + '_1_' + event.target.getAttribute('data-id') + '_1_' + event.target.getAttribute('membership') + '_1_' + event.target.getAttribute('role') + '_1_' + event.target.getAttribute('seat-id'));
            console.log(event.dataTransfer, '--拖拽开始后的参数--');
            event.dataTransfer.dropEffect = "move";
        }

    }
}
</script>
<style lang="less" scoped>
</style>
